<template>
  <div id="container" v-if="fetch_data">
    <vue-editor v-show="false"/> <!-- dummy -->
    <section v-bind:class="{signed_out: !isUserSignedIn()}">
      <div class="wrap">
        <div class="cts_box">
          <h2 class="main_ttl"><span>
            <router-link :to=spaceUrl()>{{ space_title() }}</router-link></span>
          </h2>
          <div class="room_info flex" v-if="fetch_data.space.category === 'category_social'">
            <div class="detail">
              <h3>参加者数</h3>
              <p><span>{{ delimited(fetch_data.space.join_user_count) }}</span>人</p>
            </div>
            <div class="detail">
              <h3>全投稿数</h3>
              <p><span>{{ delimited(fetch_data.space.post_count) }}</span></p>
            </div>
          </div>

          <p class="mb20" v-html="space_description()"></p>
        </div>

        <template v-if="fetch_data.space.category === 'category_social'">
          <div class="cts_box" v-if="!fetch_data.space.user_join">
            <div class="room_join_box">
              <h3 class="min_ttl">グループに参加する</h3>
              <p>このグループに投稿するためには、下の「参加する」ボタンを押してください。参加していない状態でもグループ参加権限があれば閲覧は可能です。</p>
              <div class="btn_wrap">
                <a href="javascript:void(0);" class="submit light" @click="userJoin()">グループに参加</a>
              </div>
            </div>
          </div>
        </template>

        <div class="cts_box" v-if="post_addable()">
          <div class="acc_menu post_form">
            <h3 class="min_ttl acc_tab" v-bind:class="{'open': new_post.edit_mode}" v-on:click="togglePostForm">
              <span>投稿内容の作成</span>
            </h3>
            <transition>
              <SnsPostForm :post="new_post" :tags="fetch_data.tags" v-if="new_post.edit_mode" v-on:saved="newPost"
                           v-on:draft_saved="draftSaved"/>
            </transition>
          </div>
        </div>

        <template v-if="fetch_data.space.category === 'category_social'">
          <div class="cts_box" v-if="fetch_data.space.user_join">
            <div class="room_join_box join">
              <p class="lead_txt">
                グループから退出する場合は下の「グループに参加中」ボタンからさらに表示される「グループから退出する」を選択してください。退出するとこのグループへの投稿はできなくなります。
                この操作は「
                <router-link :to="{name: 'SnsSocials'}" class="bold">テーマ別グループ一覧</router-link>
                」画面からも可能です。
              </p>
              <div class="btn_wrap">
                <a href="javascript:void(0);" class="submit light skeleton"
                   @click="show_join_menu = true">グループに参加中</a>
                <ul class="select_box" v-bind:class="{open: show_join_menu}">
                  <li><a href="javascript:void(0);" @click="unUserJoin()">グループから退出する</a></li>
                  <li><a href="javascript:void(0);" class="close" @click="show_join_menu = false">閉じる</a></li>
                </ul>
              </div>
            </div>
          </div>
        </template>

        <div class="room_info to_list flex" v-if="fetch_data.space.category === 'category_content'">
          <router-link :to="{ name: 'Archives', query: {room_ids: room_id.toString()}, hash: '#posts-list'}"
                       class="submit light skeleton arrow sml">
            講義一覧リスト
          </router-link>
        </div>

        <div class="cts_box search" v-if="false">
          <h3>表示内容の絞り込み</h3>
          <p>チェックを入れた内容で投稿の表示を絞り込みます。</p>
          <form action="">
            <div class="radio">
              <input id="radio01" type="radio" v-model="filter" value="" v-on:change="pageChanged(1)">
              <label for="radio01">すべての投稿を表示</label>

              <input id="radio03" type="radio" v-model="filter" value="links" v-on:change="pageChanged(1)">
              <label for="radio03">関連リンクあり</label>

              <input id="radio04" type="radio" v-model="filter" value="video_files" v-on:change="pageChanged(1)">
              <label for="radio04">動画データあり</label>

              <input id="radio05" type="radio" v-model="filter" value="audio_files" v-on:change="pageChanged(1)">
              <label for="radio05">音声データあり</label>

              <input id="radio06" type="radio" v-model="filter" value="image_files" v-on:change="pageChanged(1)">
              <label for="radio06">画像データあり</label>

              <input id="radio07" type="radio" v-model="filter" value="pdf_files" v-on:change="pageChanged(1)">
              <label for="radio07">PDF資料あり</label>

            </div>
            <div class="checkbox">
              <span v-for="(tag, key) in tags" :key="tag">
              <input v-bind:id="'check-' + key" type="checkbox" value="1" checked="checked">
              <label v-bind:for="'check-' + key">
                <span class="tag" v-on:click="tagClearClicked(tag)">タグ名：{{ tag }}</span>
              </label>
              </span>
            </div>
          </form>
        </div>
        <div class="cts_box" v-show="fetch_data.posts.length === 0">
          一致する投稿は見つかりませんでした。
        </div>

        <div class="cts_box" v-show="fetch_data.posts.length">
          <div class="sort flex" v-show="isUserSignedIn()">
            <p>並び替え</p>
            <select v-model="order" v-on:change="pageChanged(1)">
              <option value="">更新順</option>
              <option value="published_at_desc">投稿の新しい順</option>
              <option value="published_at_asc">投稿の古い順</option>
            </select>
          </div>

          <!--5件ごとのページ切り替え-->

          <!--フィード1セット-->
          <template v-for="post in fetch_data.posts">
            <div v-bind:id="'post-' + post.id" class="anchor_point" :key="'anchor-' + post.id"></div>
            <div class="feed_box" v-bind:id="'feed-' + post.id" v-bind:class="post.status" :key="'feed-' + post.id">
              <!-- post menu -->
              <SnsPostMenu :post="post" :show_permalink_menu="true" @editPost="editPost" @reportPost="reportPost"
                           @deletePost="deletePost" @hidePost="hidePost"
                           @playedTest="playedTest" @audioPlayedTest="audioPlayedTest"
                           v-if="isUserSignedIn()"/>

              <div class="user_info flex" v-bind:class="{ pinning: post.pinning }">
                <div v-if="post.pinning" class="pinning_icon">
                  <img src="/common/images/icon/pinning.svg">固定された投稿
                </div>
                <div class="icon achievement" v-bind:class="post.user.dan">
                  <template v-if="isUserSignedIn()">
                    <router-link
                            :to="{ name: 'SnsTimeline', params: {user_id: post.user.id}}">
                      <img v-bind:src="post.user.profile_image_url" alt="">
                    </router-link>
                  </template>
                  <template v-else>
                    <img v-bind:src="post.user.profile_image_url" alt="">
                  </template>
                </div>
                <div class="detail">
                  <div class="user_name">
                    <router-link :to="{ name: 'SnsTimeline', params: {user_id: post.user.id}}">
                      {{ post.user.sns_user_name }}
                    </router-link>
                  </div>
                  <div class="post_day">{{ moment(post.published_at, 'YYYY/MM/DD HH:mm') }}</div>
                  <div class="favorite" v-bind:class="{active: post.favorite}" v-if="isUserSignedIn()">
                    <a href="javascript:void(0)" class="flex" @click="favoritePost(post)">
                      <img v-bind:src="`/common/images/icon/star_favorite_${post.favorite}.svg`" alt="お気に入り">
                      <p>お気に入り</p>
                    </a>
                  </div>
                </div>
                <div class="hashtag" v-if="post.sns_tag">
                  <router-link :to="{name: 'SnsSearch', query: {query: tag.substring(1)}}" v-for="tag in post.sns_tag.split(' ')" :key=tag>
                    {{ tag }}
                  </router-link>
                </div>
              </div>
              <div class="tag">
                <a href="javascript:void(0)" @click="scrollTop()">{{ space_title() }}</a>
              </div>
              <div class="post_title" v-if="post.title && post.title.length > 0">
                {{ post.title }}
              </div>
              <div class="post_body ql-container ql-snow" v-if="!post.edit_mode" v-bind:id="'post-body-' + post.id"
                   v-bind:class="{height_rimit: (post.height_limit === 1 || post.height_limit === 2), open: post.height_limit === 2}">
                <div class="inner">
                  <div class="post_body ql-editor" v-html="bodyEssence(post.body)"></div>
                  <!-- ogp tag -->
                  <SnsOgpTags :ogp_tags="post.ogp_tags"/>
                  <!-- hedge txt -->
                  <SnsPostHedgeText v-if="post.user.dan === 'rank_staff'" />
                </div>
                <div class="post_more" @click="post.height_limit = post.height_limit == 1 ? 2 : 1"><span></span></div>
              </div>

              <transition>
                <div class="cts_box" v-if="post.edit_mode">
                  <div class="acc_menu post_form post_form_edit">
                    <h3 class="min_ttl acc_tab" v-bind:class="{'open': post.edit_mode}" v-on:click="editPost(post)">
                      <span>投稿内容の編集</span>
                    </h3>
                    <SnsPostForm :post="post" :tags="fetch_data.tags" v-on:saved="savePost"/>
                  </div>
                </div>
              </transition>

              <div v-if="!post.edit_mode">
                <!-- post files -->
                <SnsFiles :files="post.files"/>

                <div class="post_info flex" v-if="isUserSignedIn()">
                  <div class="nice_box flex">
                    <div class="nice" v-on:mouseover="likeOver(post)" v-on:mouseleave="likeLeave(post)"
                         @click="likeClick(post)"><span>{{ delimited(post.like_user_count) }}</span></div>
                    <div class="btn_nice" v-bind:class="{active: post.like_user}" v-on:click="likeUserPost(post)">
                      <span>いいね！</span>
                    </div>
                    <SnsLikeUserList :post="post" v-if="post.show_like_list"/>
                    <SnsLikeUserModal :post="post" v-if="post.show_like_modal" @close="likeModalClose(post)"
                                      @follow="follow" @un_follow="unFollow"/>
                  </div>
                  <div class="comment">コメント<span>{{ delimited(post.comment_count) }}</span>件</div>
                </div>
                <div class="comment_more" v-if="isUserSignedIn() && post.comments.length < post.comment_count"
                     v-on:click="fetchComments(post)">
                  以前のコメントを全て見る（<span>{{ delimited(post.comment_count) }}</span>件）
                </div>

                <!--コメント1セット-->
                <div v-if="comment_addable()">
                  <div class="comment_body flex" v-for="(comment, key) in post.comments" :key="key"
                       v-bind:id="'comment-' + comment.id">
                    <div class="icon achievement" v-bind:class="comment.user.dan">
                      <router-link
                          :to="{ name: 'SnsTimeline', params: {user_id: comment.user.id}}">
                        <img v-bind:src="comment.user.profile_image_url" alt="">
                      </router-link>
                    </div>
                    <div class="comment_post_box">
                      <div class="inner">
                        <!-- comment menu -->
                        <SnsCommentMenu :comment="comment" @editComment="editComment(comment)"
                                        @reportComment="reportComment(post, comment)"
                                        @deleteComment="deleteComment(post, comment)"/>

                        <div class="head flex">
                          <div class="user_name">
                            <router-link :to="{ name: 'SnsTimeline', params: {user_id: comment.user.id}}">
                              {{ comment.user.sns_user_name }}
                            </router-link>
                          </div>
                          <div class="post_day">{{ moment(comment.updated_at, 'YYYY/MM/DD HH:mm') }}</div>
                        </div>
                        <div class="comment_post_body ql-container ql-snow" v-if="!comment.edit_mode">
                          <div class="post_body ql-editor" v-html="bodyEssence(comment.body)"></div>
                        </div>

                        <transition>
                          <SnsPostCommentForm :post="post" :comment="comment" v-if="comment.edit_mode"
                                              v-on:saved="saveComment"/>
                        </transition>
                      </div>
                      <div v-if="!comment.edit_mode">
                        <SnsFiles :files="comment.files"/>
                        <div class="comment_info flex">
                          <div class="comment_nice" v-bind:class="{active: comment.like_user}"
                               v-on:click="likeUserComment(comment)">いいね！
                          </div>
                          <div class="count" v-on:mouseover="commentLikeOver(comment)"
                               v-on:mouseleave="commentLikeLeave(comment)" @click="commentLikeClick(comment)">
                            （<span>{{ delimited(comment.like_user_count) }}</span>）
                          </div>
                          <SnsLikeUserList :comment="comment" v-if="comment.show_like_list"/>
                          <SnsLikeUserModal :comment="comment" v-if="comment.show_like_modal"
                                            @close="commentLikeModalClose(comment)" @follow="follow"
                                            @un_follow="unFollow"/>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!--コメント1セットここまで-->
                  <div class="post_btn flex">
                    <div class="btn_comment" v-if="comment_addable()" v-bind:class="{disabled: !isUserJoin()}"
                         v-on:click="togglePostCommentForm(post)">
                      <span>コメントする</span></div>
                  </div>
                </div>

                <transition>
                  <SnsPostCommentForm :post="post" :comment="new_comment"
                                      v-if="new_comment.edit_mode && new_comment.sns_post_id === post.id"
                                      v-on:saved="newComment"/>
                </transition>
              </div>
            </div>
          </template>
          <infinite-loading ref="infiniteLoading" spinner="spiral" @infinite="infiniteHandler"
                            v-if="infiniteLoadingEnabled">
            <span slot="no-more">これ以上の投稿はありません</span>
          </infinite-loading>
          <!--pager-->
          <Pagination :pagination="fetch_data.pagination" v-on:changed="pageChanged" v-if="!infiniteLoadingEnabled"/>
          <!--//pager-->
        </div>
      </div>
    </section>

  </div>
</template>

<script>
import Libraries from '@/components/Libraries.vue'
import Pagination from '@/components/Pagination.vue'
import SnsOgpTags from '@/components/sns/SnsOgpTags.vue'
import SnsFiles from '@/components/sns/SnsFiles.vue'
import SnsCommons from '@/components/sns/SnsCommons.vue'
import SnsPostMenu from '@/components/sns/SnsPostMenu.vue'
import SnsPostForm from '@/components/sns/SnsPostForm.vue'
import SnsPostCommentForm from '@/components/sns/SnsPostCommentForm.vue'
import SnsCommentMenu from '@/components/sns/SnsCommentMenu.vue'
import SnsLikeUserList from '@/components/sns/SnsLikeUserList'

import {VueEditor} from 'vue2-editor';
import SnsLikeUserModal from "@/components/sns/SnsLikeUserModal";
import SnsPostHedgeText from '@/components/sns/SnsPostHedgeText.vue'

export default {
  mixins: [Libraries, SnsCommons],
  components: {
    Pagination,
    SnsOgpTags,
    SnsFiles,
    SnsPostMenu,
    SnsPostForm,
    SnsPostCommentForm,
    SnsCommentMenu,
    SnsLikeUserList,
    SnsLikeUserModal,
    VueEditor,
    SnsPostHedgeText
  },
  methods: {
    // 無限スクロール
    infiniteHandler($state) {
      if (this.fetch_data.pagination.last_page) {
        $state.complete()
      } else {
        this.page += 1
        this.fetchData()
      }
    },

    fetchData() {
      if (this.fetch_data === null || !this.infiniteLoadingEnabled) {
        this.scrollTop()
        this.startAjax()
      }
      this.axios
          .get(`${this.env.api_base_url}sns/posts_space.json`, {
            params: {
              space_id: this.space_id,
              room_id: this.room_id,
              tags: this.tags.join(' '),
              page: this.page,
              filter: this.filter,
              order: this.order,
            },
          })
          .then(response => {
            // console.log(response.data)
            this.addFrontParams(response.data)
            if (this.fetch_data === null || !this.infiniteLoadingEnabled) {
              this.fetch_data = response.data
            } else {
              this.fetch_data.posts = this.fetch_data.posts.concat(response.data.posts)
              this.fetch_data.pagination = response.data.pagination
            }
          })
          .catch((response) => {
            this.errorAjax(response)
          })
          .finally(() => {
            if (this.$refs.infiniteLoading) this.$refs.infiniteLoading.stateChanger.loaded()
            // 投稿の要素の高さを調べる
            this.checkPostBodyHeight(this.fetch_data.posts)
            this.finishAjax()
            if (location.hash) {
              setTimeout(() => {
                this.scrollTo(location.hash)
              }, 200)
            }
            this.$nextTick(() => {
              this.fetch_data.posts.forEach((post) => {
                this.vimeoPlayTrigger(post)
              });
            });
          })
    },
    userJoin() {
      setTimeout(() => {
        this.axios
            .post(`${this.env.api_base_url}sns/social_space_user_join.json`, {
              id: this.fetch_data.space.id
            })
            .then(() => {
              this.fetch_data.space.post_addable = true
              this.fetch_data.space.comment_addable = true
              this.fetch_data.space.user_join = true
            })
            .catch((response) => {
              this.errorAjax(response)
            })
            .finally(() => {
              this.finishAjax()
            })
      }, 100)
    },
    unUserJoin() {
      this.axios
          .post(`${this.env.api_base_url}sns/social_space_un_user_join.json`, {
            id: this.fetch_data.space.id
          })
          .then(() => {
            this.show_join_menu = false
            this.fetch_data.space.comment_addable = false
            this.fetch_data.space.user_join = false
          })
          .catch((response) => {
            this.errorAjax(response)
          })
          .finally(() => {
            this.finishAjax()
          })
    },

    // tag クリア
    tagClearClicked(tag) {
      const index = this.tags.indexOf(tag)
      if (index !== -1) {
        this.tags.splice(index, 1)
        this.pageChanged(1)
      }
    },

    // tag 選択
    tagClicked(tag) {
      if (this.tags.indexOf(tag) === -1) {
        this.tags.push(tag)
        this.pageChanged(1)
      }
    },

    // ページネーション
    pageChanged(page) {
      const tags = this.$route.query.tags == null ? '' : this.$route.query.tags
      const filter = this.$route.query.filter == null ? '' : this.$route.query.filter
      const order = this.$route.query.order == null ? '' : this.$route.query.order
      if (this.page !== page || tags !== this.tags || filter !== this.filter || order !== this.order) {
        let query = {}
        if (this.tags.length > 0) query.tags = this.tags.join(' ')
        if (this.filter !== '') query.filter = this.filter
        if (this.order !== '') query.order = this.order
        if (page !== 1) query.page = page
        this.$router.push({
          name: 'SnsSpace',
          params: this.$route.params,
          query: query
        })
      }
    }
  }
}
</script>

<style lang="scss">
.btn_comment {
  &.disabled {
    opacity: 0.5;
    pointer-events: none !important;
    cursor: none !important;
  }
}
</style>